@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  background-color: aliceblue;
}

.link-nav {
  color: white;

  font-weight: 500;
  display: block;
  padding: 10px;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.3s, transform 0.3s;
}

.link-nav:hover {
  color: rgb(245, 232, 59);
  transform: scale(1.09);
}

.titulo {
  padding-top: 10px;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 50px;
}

.navDesign {
  background-color: #fc9d38 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #c9bfa9 !important;
}

.loading-dots {
  animation: loading-dots 1s infinite;
}

@keyframes loading-dots {
  0%,
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cardDesign {
  transition: transform 0.3s ease-in-out;
}

.cardDesign:hover {
  transform: scale(1.05);
}

.sidebar-header {
  background-color: #fc9d38;
  color: white;
  border-bottom: 2px solid #c9bfa9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sidebar-titulo {
  font-family: "Roboto";
}

.container-cart {
  margin-bottom: 5px;
}

.sidebar-footer {
  border-top: #c9bfa9 2px solid;
  padding: 10px;
  background-color: #fc9d38;
  color: white;
}

.divSvgCart {
  transition: transform 0.3s ease-in-out;
}

.divSvgCart:hover {
  transform: scale(1.08);
}

.vinculoCart {
  border: 1px solid rgb(247, 247, 247);
  padding: 8px;
  border-radius: 100%;
  margin-right: 10px;
}

.bodyLogin {
  position: relative; /* Necesario para posicionar el pseudo-elemento */
  background-image: url("../../public/pons-machali.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bodyLogin::before {
  content: ""; /* Necesario para el pseudo-elemento */
  position: absolute; /* Posiciona el pseudo-elemento encima del fondo */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(19, 16, 16, 0.5); /* Color oscuro con opacidad */
}

.loginContainer {
  position: relative; /* Necesario para que el z-index funcione */
  z-index: 1; /* Coloca el contenedor sobre el pseudo-elemento */
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  
}

.tituloLogin {
  font-family: "Roboto";
  text-align: center;
}

.erroresModal {
  border: 1px solid red;
  border-radius: 10px;
  background-color: #fbe2e2;
}

.divBoton {
  -webkit-box-shadow: 7px 6px 5px 0px rgba(0,0,0,0.17);
  -moz-box-shadow: 7px 6px 5px 0px rgba(0,0,0,0.17);
  box-shadow: 7px 6px 5px 0px rgba(0,0,0,0.17);
  background-color: #ffae63;
  border-radius: 20px;
  padding: 20px;
}

.noProductos{
  font-weight: bold;
  text-decoration: underline;
  font-size: 16px;
}

.sombraTabla{

  -webkit-box-shadow: 7px 6px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 7px 6px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 7px 6px 5px 0px rgba(0,0,0,0.25);
}

.error{
  color: red;
  text-decoration: underline;
}

.hidden{
  color: transparent;
}


.swal2-html-container{
  overflow: hidden !important;
}

.img-table{
  transition: transform 0.2s ease;
}

.img-table:hover{
  transform: scale(1.1);
}